@font-face {
    font-family: GraphikRegular ;
    src: url(./Graphik-Font-Family/GraphikRegular.otf);
  }
  @font-face {
    font-family: GraphikMedium ;
    src: url(./Graphik-Font-Family/GraphikMedium.otf);
  }
  @font-face {
    font-family: GraphikSemibold ;
    src: url(./Graphik-Font-Family/GraphikSemibold.otf);
  }
  @font-face {
    font-family: GraphikBold;
    src: url(./Graphik-Font-Family/GraphikBold.otf);
  }
  
  .medium{
    font-family: GraphikMedium ;
  }
  .semibold{
    font-family: GraphikSemibold ;
  }
  .bold{
    font-family: GraphikBold ;
  }
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: GraphikRegular, 'Poppins';
    border-color:#e0e6ee;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card_box {
    /* width: 200px;
    height: 250px; */
    /* border-radius: 20px;
    background: linear-gradient(170deg, rgba(58, 56, 56, 0.623) 0%, rgb(31, 31, 31) 100%);
    position: relative;
    box-shadow: 0 25px 50px rgba(0,0,0,0.55);
    cursor: pointer;
    transition: all .3s; */
  }
  

  
  .card_box .tag {
    position: absolute;
    overflow: hidden;
    width: 100px;
    height: 100px;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card_box .tag::before {
    content: ' Elyzian';
    font-family: GraphikMedium ;

    font-size: 12px;
    position: absolute;
    width: 150%;
    height: 40px;
    background-image: linear-gradient(45deg, #353332 0%, #3c3a36  51%, #000000  100%);
    transform: rotate(-45deg) translateY(-20px);
    display: flex;
    align-items: center;
    overflow-wrap: break-word;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.1em;
    /* text-transform: uppercase; */
    box-shadow: 0 5px 10px rgba(0,0,0,0.23);
  }
  
  .card_box .tag::after {
    content: '';
    position: absolute;
    width: 10px;
    bottom: 0;
    left: 0;
    height: 10px;
    z-index: -1;
    box-shadow: 140px -40px #2d2b2b;
    background-image: linear-gradient(45deg, #353332 0%, #3c3a36  51%, #000000  100%);
  }
 
  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #0084ff;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 